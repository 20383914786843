import { useState, useMemo } from 'react';
import moment from 'moment';

import { user } from 'services';
import { deleteSubscription } from 'services/payments-service';
import { TableExt, TableInfo, Confirm, themes, GridBox, GridUpdateMessage } from 'libs/ui';
import SubscriptionForm from './forms/subscription-form';
import { ExtensionForm } from './forms/extension-form';
import { getTableParams } from './grid.params';

export const SubscriptionGrid = (props) => {
  const { count, entries, className, theme, getXlsAction } = props;
  //const interface1 = props.interface;
  const [showForm, setShowForm] = useState('');
  const [message, setMessage] = useState('');
  const [row, setRow] = useState({});
  const [confirm, setConfirm] = useState(null);

  /**
   * close confirm
   */
  const closeConfirm = () => {
    setConfirm(null);
  };

  /**
   * delete item
   */
  const delItem = (id) => {
    deleteSubscription(id).then(() => {
      props.getEntries({ offset: 0 }, null, true);
      closeConfirm();
    });
  };

  // actions
  const addItemAction = () => {
    setRow({});
    setShowForm('addForm');
  };

  const editItemAction = (row) => {
    setRow(row);
    setShowForm('editForm');
  };

  const activateItemAction = (row) => {
    setRow(row);
    setShowForm('extForm');
  };

  const delItemAction = (row) => {
    setRow(row);
    setConfirm({
      title: 'СО ' + row.mean_id + ' будет удалено. Продолжить?',
      width: '440px',
      type: 'danger',
      cancelButton: { text: 'Отмена', func: closeConfirm },
      acceptButton: { text: 'Удалить', func: () => delItem(row.id), theme: themes.red },
    });
  };

  /**
   * close form
   * @returns
   */
  const closeForm = () => setShowForm('');

  /**
   * close ext form
   * @param {*} success
   */
  const closeExtensionForm = (success = false) => {
    closeForm();
    if (success) {
      setMessage('Абонемент активирован');
      setTimeout(() => setMessage(''), 3000);
    }
  };

  // items
  const items = Object.keys(entries).map((key) => entries[key]);

  // actions
  const rightEdit = user.haveRight('subscriptions.update');
  const rightDel = user.haveRight('subscriptions.delete');
  const rightAdd = user.haveRight('subscriptions.add');
  const actions = [];
  if (rightEdit) {
    actions.push({ title: 'Активировать', action: activateItemAction, isVisible: (row) => !row.is_blocked });
    // actions.push({ title: 'Редактировать', action: editItemAction });
  }
  if (rightDel) {
    actions.push({ title: 'Удалить', action: delItemAction });
  }

  // top actions
  const topActions = [];
  if (rightAdd) {
    topActions.push({ title: 'Добавить Абонемент', icon: 'fa-plus', theme: themes.blue, action: addItemAction });
  }
  if (count) {
    getXlsAction(topActions);
  }

  const d1 = moment().endOf('day').subtract(1, 'days');
  const initial =
    showForm === 'addForm'
      ? {
          // vehicle_category: getFirstCategory(),
          start_at: d1.format(),
          end_at: d1.format(),
          qrtoken: false,
        }
      : { ...row };

  const tableParams = useMemo(() => getTableParams(), []);

  return (
    <GridBox>
      <TableInfo theme={theme} count={count} actions={topActions} />

      <TableExt
        theme={theme}
        title="Абонемент"
        rows={{ count, items }}
        actions={actions}
        className={className}
        refresh={props.refresh}
        clientId={props.clientId}
        onRowClick={rightEdit ? editItemAction : undefined}
        {...tableParams}
      />

      {confirm && <Confirm {...confirm} />}

      {showForm && showForm !== 'extForm' && (
        <SubscriptionForm
          type={showForm}
          close={closeForm}
          //id={props.id}
          clientId={props.clientId}
          id={row.id}
          meanId={row.mean_id}
          meanType={row.mean_type}
          setMessage={setMessage}
          refresh={props.refresh}
          tariffs={props.tariffs}
          initialValues={initial}
        />
      )}

      {showForm === 'extForm' && (
        <ExtensionForm refresh={props.refresh} id={row.id} mean_id={row.mean_id} close={(result) => closeExtensionForm(result)} />
      )}

      {message && <GridUpdateMessage>{message}</GridUpdateMessage>}
    </GridBox>
  );
};
