import React from 'react';
import styled, { css } from 'styled-components';

import { FieldComponent } from './field-component';

export const StyledField = styled((props) => <FieldComponent {...props} />)`
  position: relative;
  width: 590px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #595d64;
  label {
    font-size: 13px;
    color: #fff;
    opacity: 0.6;
    min-width: 200px;
  }
  input,
  select,
  .input {
    width: 430px;
    height: 100%;
    font-size: 15px;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    color: #fff;
    opacity: 0.6;
    &:focus {
      outline: none;
    }
  }
  select {
    position: relative;
    /*
    left: -3px;
    */
    ${(props) =>
      props.noApperance &&
      css`
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
      `}
  }
  option {
    padding: 10px;
    background-color: #393e44;
    color: #fff;
    opacity: 0.6;
  }
  &.checkbox1 input {
    width: 20px;
  }
  &.shortfield input,
  &.shortfield select {
    width: 393px;
  }

  ${(props) =>
    props.noaf &&
    css`
      input {
        text-security: disc;
        -webkit-text-security: disc;
      }
    `}
`;

export const WhiteStyledField = styled(StyledField)`
  width: 410px;
  border-bottom: solid 1px #8e8e8e;
  label {
    color: #3e3e3e;
  }
  input,
  select,
  textarea {
    width: 250px;
  }
  input {
    padding-left: 4px;
  }

  input,
  select,
  i {
    color: #000;
    opacity: 1;
  }
  option {
    background-color: #fff;
    opacity: 1;
    color: #000;
  }
  textarea {
    height: 100%;
    font-size: 15px;
    border: none;
    outline: none;
    resize: none;
  }
  [type='checkbox'] {
    height: auto;
    width: auto;
  }
  &.hidden {
    display: none;
  }
  select[name='vehicle_class'],
  select[name='vehicle_category'],
  &.shortfield input,
  &.shortfield select {
    width: 200px !important;
  }
  input[disabled] {
    /* color: #aaa;     */
    background-color: #eee;
  }
  height: 40px;
  display: flex;
  align-items: center;
  label {
    margin: 0 10px 0 0;
    font-size: 15px;
    color: #000;
    opacity: 1;
  }
  & > .toggle {
    position: relative;
    height: 20px;
    width: 38px;
    margin: 0;
    span {
      cursor: pointer;
      top: 1px;
      left: 1px;
      position: absolute;
      z-index: 1;
      border-radius: 50%;
      background-color: #fff;
      width: 18px;
      height: 18px;
      transition: 0.2s;
    }
    input {
      height: 20px;
      width: 38px;
      position: relative;
      cursor: pointer;
      appearance: none;
      border: 0;
      border-radius: 20px;
      outline: none;
      background-color: #d0d2d6;
      &:checked {
        background-color: #3ea5ff;
      }
      &:checked + span {
        left: 18px;
      }
    }
  }
`;

export const DarkMultiselect = styled(StyledField)`
  & .popup {
    background-color: #4f5761;
    border: solid 1px #a3b0c0;
    max-height: 400px;
    overflow-y: auto;
  }
`;

export const Title = styled.h1`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #262b31;
  margin: 0 0 15px;
`;
