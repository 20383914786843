import styled from 'styled-components';

export const Content = styled.div`
  padding: 15px 0;
  min-height: 280px;
  img {
    max-width: 100%;
    height: auto;
  }
`;

export const Error = styled.div`
  color: #f00;
`;
