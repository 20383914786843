import styled, { css } from 'styled-components';

export const Content = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  width: 100%;
  min-height: ${(props) => (props.height ? props.height : '50px')};
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: url(/images/loader.svg) no-repeat 50% 50%;
  }
  ${(props) =>
    props.theme === 'dark' &&
    css`
      background: #393e44;
      &:after {
        background: url(/images/loader-white.svg) no-repeat 50% 50%;
      }
    `}
  ${(props) =>
    props.$absolute &&
    css`
      position: absolute;
    `}
`;
