import { useState } from 'react';
import moment from 'moment';
import { reduxForm, SubmissionError } from 'redux-form';

import { user } from 'services';
import { addSubscription, updateSubscription, getClientsDict } from 'services/payments-service';
import { getCategoriesList, getVehicleClassesList, getOptionsList } from 'services/types';
import { Confirm, Button, themes, Popup2, ParkingSession, QrInfo } from 'libs/ui';
import { ParkingFilter2, required, WhiteStyledField, Title, ErrorMsg, DictInput } from 'libs/form';
import { StyledButtonContainer, QrButton } from './subscription-form.styled';

/**
 * subscriotion form
 * @param {*} props
 * @returns
 */
const SubscriptionForm = (props) => {
  const { error, destroy, refresh, setMessage, form, handleSubmit, close, type, clientId, id, meanId, meanType, tariffs } = props;
  const [confirm, setConfirm] = useState(false);
  const [isAfterSubmit, setIsAfterSubmit] = useState(false);
  const [data, setData] = useState(null);
  const [qrValue, setQrValue] = useState(!!props.initialValues.qrtoken);
  const rightSessionActive = user.haveRight('subscriptions.show_active_stay');
  const isAdd = type === 'addForm';
  const [qrshow, setQrshow] = useState(false);

  /**
   * update qr checkbox value
   * @param {*} event
   */
  const updateQrValue = (event) => {
    setQrValue(event.target.checked);
  };

  /**
   * close confirm
   */
  const closeConfirm = () => {
    setConfirm(false);
  };

  /**
   * close form
   */
  const cancel = () => {
    close();
    destroy(form);
  };

  /**
   * submit
   * @param {*} values
   * @returns
   */
  const submit = (values) => {
    // data
    const test = false;
    const out = { ...values };
    out.is_blocked = out.is_blocked === 'true' || out.is_blocked === true;
    out.mean_type = 'subscription';
    out.vehicle_category = out.vehicle_category ? parseInt(out.vehicle_category) : null;
    out.vehicle_class = out.vehicle_class ? parseInt(out.vehicle_class) : null;
    out.tariff_id = out.tariff_id ? out.tariff_id : null;
    if (!out.mean_id) {
      out.mean_id = moment().format('X');
    }
    // if (clientId) {
    //   out.client_id = clientId;
    // }
    if (!out.client_id && clientId) {
      out.client_id = clientId;
    }
    if (!clientId && !out.client_id) {
      out.client_id = null;
      out.client_name = null;
    }

    // dates
    if (out.start_at && typeof out.start_at !== 'string') {
      out.start_at = out.start_at.format();
    }
    if (out.end_at && typeof out.end_at !== 'string') {
      out.end_at = out.end_at.format();
    }

    if (!isAdd) {
      out.id = id;
      // confirm
      if (out.mean_id !== props.initialValues.mean_id) {
        setData(out);
        setConfirm(true);
        return false;
      }
    }
    if (test) {
      console.log('🚀 ~ submit ~ out:', out);
      return Promise.resolve();
    }

    // method
    const method = isAdd ? addSubscription : updateSubscription;
    return method(out)
      .then((data) => {
        afterSubmit(data);
      })
      .catch((errors) => {
        // console.log('🚀 ~ submit ~ errors:', errors);
        if (errors && errors.data) {
          throw new SubmissionError({
            _error: errors.data.type,
          });
        }
        if (errors && errors.field && errors.message) {
          const fields = {
            mean_id: 'Номер',
          };
          const fn = fields[errors.field] ?? errors.field;
          const out = {
            _error: fn + ' ' + errors.message.toLowerCase(),
            [errors.field]: errors.message,
          };
          // console.log('🚀 ~ submit ~ out:', out);
          throw new SubmissionError(out);
        }
      });
  };

  /**
   * submit after confirm
   * @returns
   */
  const submitAfterConfirm = () => {
    return updateSubscription(data)
      .then(() => {
        afterSubmit();
      })
      .catch((errors) => {
        if (errors && errors.data) {
          throw new SubmissionError({
            _error: errors.data.type,
          });
        }
      });
  };

  /**
   * after submit
   */
  const afterSubmit = (data) => {
    refresh();
    setMessage('Изменения сохранены');
    setTimeout(() => {
      setMessage('');
    }, 3000);

    // show qr
    if (isAdd && qrValue) {
      setData(data);
      setIsAfterSubmit(true);
      setQrshow(true);
      return;
    }

    cancel();
  };

  /**
   * get clients for autocomplete
   * @param {*} name
   * @returns
   */
  const getClients = (name) => {
    return getClientsDict({
      filter_params: { name },
      sort_params: [{ field_name: 'name', direction: 'asc' }],
      paging_params: { limit: 5, offset: 0 },
    });
  };

  /**
   * show qr
   * @param {*} event
   */
  const show = (event) => {
    event.preventDefault();
    setQrshow(true);
  };

  /**
   * hideqr
   */
  const hide = () => {
    setQrshow(false);
    if (isAdd) {
      cancel();
    }
  };

  const title = isAdd ? 'Добавить СО' : 'Изменить СО';

  return (
    <>
      {!isAfterSubmit && (
        <Popup2 w={482} top={3}>
          <Title>{title}</Title>
          <form onSubmit={handleSubmit(submit)}>
            <ErrorMsg error={error} />
            <ParkingFilter2 />

            {!clientId && (
              <WhiteStyledField
                selectedName={props.initialValues.client_name}
                label="Клиент"
                placeholder="Клиент"
                type="autocomplete2"
                name="client_id"
                getList={getClients}
              />
            )}

            <DictInput dict="payment_mean.status" />

            <WhiteStyledField type="text" label="Номер" placeholder="3786 4678 6467 5656" name="mean_id" validate={qrValue ? undefined : required} />

            <WhiteStyledField type="text" label="ГРЗ" placeholder="А000AA 000 rus" name="vehicle_plate" />

            <WhiteStyledField type="text" label="Держатель" placeholder="Введите текст" name="owner" validate={required} />

            <WhiteStyledField validate={required} type="select" label="Класс ТС" name="vehicle_class" className="shortfield">
              <option value={null}>Не задано</option>
              {getVehicleClassesList()}
            </WhiteStyledField>

            <WhiteStyledField type="select" label="Категория" name="vehicle_category" className="shortfield">
              <option value={null}>Не задано</option>
              {getCategoriesList()}
            </WhiteStyledField>

            <WhiteStyledField
              className="shortfield"
              type="dateTime"
              label="Дата действия с"
              placeholder="Дата действия с"
              name="start_at"
              validate={required}
            />

            <WhiteStyledField
              className="shortfield"
              type="dateTime"
              label="Дата действия по"
              placeholder="Дата действия по"
              name="end_at"
              validate={required}
            />

            <WhiteStyledField className="shortfield" type="select" label="Тариф" name="tariff_id">
              <option value="">Не задано</option>
              {getOptionsList(tariffs)}
            </WhiteStyledField>

            {isAdd && (
              <WhiteStyledField onChange={updateQrValue} type="toggle" label="Проезд по QR-коду" placeholder="Проезд по QR-коду" name="qrtoken" />
            )}

            {!isAdd && !!props.initialValues.qrtoken && (
              <QrButton>
                <Button type="button" onClick={show}>
                  Показать QR
                </Button>
              </QrButton>
            )}

            {!isAdd && rightSessionActive && <ParkingSession meanType={meanType} meanId={meanId} />}

            <StyledButtonContainer space="20px">
              <Button type="button" onClick={cancel}>
                Отменить
              </Button>
              <Button theme={themes.blue}>
                <i className="fa fa-save" />
                Сохранить
              </Button>
            </StyledButtonContainer>

            {confirm && (
              <Confirm
                width={'550px'}
                title={'Номер карты для данной записи будет изменён. Предыдущий номер карты будет удалён. Продолжить?'}
                type="danger"
                cancelButton={{ text: 'Отмена', func: closeConfirm }}
                acceptButton={{ text: 'Изменить номер карты', func: submitAfterConfirm, theme: themes.red }}
              />
            )}
          </form>
        </Popup2>
      )}
      {qrshow && <QrInfo id={id ?? data.id} close={hide} />}
    </>
  );
};

//vehicle_category: props.categories ? props.categories[0].id : null,

export default reduxForm({
  form: 'subscriptionForm',
  destroyOnUnmount: false,
})(SubscriptionForm);
