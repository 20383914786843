import { useState, useEffect } from 'react';

import { getQr } from 'services/payments-service';
import { Loader2 } from '../loader2/loader2';
import { Popup2 } from '../popup2/popup2';
import { Content, Error } from './qr-info.styled';

export const QrInfo = (props) => {
  const { close, id } = props;

  const [ready, setReady] = useState(false);
  const [qr, setQr] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    getQr(id)
      .then((data) => {
        setQr(data.base64);
        setError('');
      })
      .catch((error) => {
        setQr('');
        setError(error.toString());
      })
      .finally(() => {
        setReady(true);
      });
  }, [id]);

  const closePopup = () => {
    close();
  };

  const fn = 'data:image/png;base64,' + qr;
  return (
    <Popup2 close={closePopup} w={320}>
      <Content>{!ready ? <Loader2 height={'250px'} /> : error ? <Error>{error}</Error> : <img src={fn} alt={'qr'} />}</Content>
    </Popup2>
  );
};
